
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'base-pre',
  setup() {
    return {}
  },
  props: {
    title: {
      default: 'Debug view',
      type: String,
      required: true,
    },
    hide: {
      default: false,
      type: Boolean,
      required: false,
    },
    data: {
      required: true,
    },
  },
})
