
import {
  ref,
  provide,
  computed,
  reactive,
  onMounted,
  defineComponent,
} from 'vue'
import ApiService from '@/core/services/ApiService'
import isEmptyObject from '@/core/helpers/isEmptyObject'
import NewsFilter from '@/core/components/filters/NewsFilter.vue'
import InterfaceFilterNewsData from '@/assets/ts/_utils/models/FilterNewsData'
import BasePre from '@/core/components/base/BasePre.vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'news-list',
  components: {
    BasePre,
    NewsFilter,
  },
  setup() {
    /**
     * Interface for the single photo.
     */
    interface InterfacePhotoItem {
      id: number
      original_name: string
      path: string
    }

    /**
     * Interface for the source type.
     */
    interface InterfaceSourceType {
      type: string
    }

    /**
     * Interface for the single news object.
     */
    interface InterfaceNewsObject {
      ad_rubrics: Array<{}>
      approved_log: {}
      author: {}
      category: {}
      cover: string
      created_at: string
      created_by_admin_user: {}
      id: number
      is_on_main: number
      is_published: number
      previewPhotos: Array<string>
      photos: Array<InterfacePhotoItem>
      preview_text: string
      rejected_log: {}
      slug: string
      status: string
      text: string
      title: string
      updated_at: string
      updated_by_admin_user: {}
      sources?: Array<InterfaceSourceType>
      sourcesNews?: InterfaceSourceType
      sourcesPhotos?: InterfaceSourceType
      checked?: boolean
      reasons?: Array<number>
    }

    const rowClasses = 'd-flex flex-column align-items-start justify-content-start'

    /**
     * This function converts requested data for the table.
     * @param {InterfaceNewsObject[]}
     * @returns {InterfaceNewsObject[]} that number, plus one.
     */
    const convertDataForEdit = (
      items: InterfaceNewsObject[]
    ): InterfaceNewsObject[] => {
      return items.map(item => {
        item.previewPhotos = item.photos.map(photoItem => photoItem.path)
        item.reasons = []
        item.checked = false
        item.sources.forEach(sourcesItem => {
          if (sourcesItem.type === 'source_news') {
            item.sourcesNews = sourcesItem
          }
          if (sourcesItem.type === 'source_image') {
            item.sourcesPhotos = sourcesItem
          }
        })
        return item
      })
    }

    /**
     * Generate the icon title depends on the status of the news.
     * @returns {string}.
     */
    const generateNewsTitle = status => {
      return status === 'confirmed' ? 'Одобренные' : status === 'rejected' ? 'Отклонённые' : status === 'not_verified' ? 'Не проверенные' : 'Nothing'
    }

    const reasons = ref([])
    const newsLoading = ref(false)
    const checkedModel = ref(false)
    const requestedNewsList = ref<InterfaceNewsObject[]>([])
    const newsPaginationPagesNumber = ref(0)
    const newsPaginationItemsNumber = ref(0)

    /**
     * Detect whether the table has a content.
     * Needed for the table loader.
     * @returns {Boolean}.
     */
    const tableHasContent = computed(() => {
      return requestedNewsList.value.length > 0 && !newsLoading.value
    })

    /**
     * Detect whether one or more checkboxes are checked.
     * Needed for BULK buttons.
     * @returns {Boolean}.
     */
    const someCheckboxesAreChecked = computed(() => {
      return requestedNewsList.value.some(item => item.checked === true)
    })

    /**
     * This function calculate width of table columns depends on the table loading state.
     * If table is loading then we should collapse columns to 0 width.
     * @param {Number} Width of the column.
     */
    const calcWidth = width => {
      return !newsLoading.value && !requestedNewsList.value.length ? 0 : width
    }


    const filterData = reactive({
      page: null,
      limit: null,
      'filter[status]': null,
      'filter[title]': null,
      'filter[category]': [],
      'filter[ad_rubric_id]': [],
      'filter[user_id]': [],
      'filter[id]': [],
      'filter[date][from]': null,
      'filter[date][to]': null,
    } as InterfaceFilterNewsData)

    provide('filterData', filterData)

    /**
     * This function make the news array empty.
     */
    const clearTheNewsList = () => {
      requestedNewsList.value = []
    }

    /**
     * This function sets the current page for the pagination.
     * @param {Number} Curent page number.
     */
    const setCurrentPage = val => {
      filterData.page = val
    }

    /**
     * Adds the filter params(converted string) to the window URL.
     * Make a concat of the url's parts.
     * This recreates an URL with new parameters,
     * gotted from the filter(protocol + host + path + params).
     * @param {Object}
     */
    const syncParamsToUrl = params => {
      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        params
      // This replaces the current URL with the recreated.
      window.history.pushState({ path: newurl }, '', newurl)
    }

    /**
     * Converts filter object to the URL params string.
     * @param {InterfaceFilterNewsData} filter data.
     * @returns {String} concatenated params.
     */
    const convertFilterToParams = (data: InterfaceFilterNewsData) => {
      let paramsAsString = ''
      // Iterate the filter object params.
      for (const [key, value] of Object.entries(data)) {
        // If the iterated item type is not a NUMBER (means is Array or String)
        const valueBoolean =
          value && typeof value !== 'number'
            ? Boolean(value.length)
            : Boolean(value)
        // If the iterated item is not a
        if (valueBoolean) {
          if (!paramsAsString.length) {
            // The 1st element in the params row.
            paramsAsString += key + '=' + value
          } else {
            // The rest elements in the params row.
            paramsAsString += '&' + key + '=' + value
          }
        }
      }
      return paramsAsString
    }

    /**
     * This function requests reasons.
     * @returns {Array} Array of reasons for decline.
     */
    const requestReasons = async () => {
      const reasonsObject = await ApiService.query(
        '/admin/reference/moder-reasons?type=news',
        {}
      )
        .then(response => {
          return response.data
        })
        .catch(() => {
          console.error('Error to get reasons')
        })

      if (reasonsObject && !isEmptyObject(reasonsObject)) {
        for (const [key, value] of Object.entries(reasonsObject)) {
          reasons.value.push({
            key,
            value,
          })
        }
      } else {
        ElMessage.warning('Что-то не так с запросом причин')
      }
    }

    const sortDirection = ref(false)

    /**
     * Requests a bunch of news.
     * @param {Object} Fitler data properties.
     */
    const requestNews = () => {
      checkedModel.value = false
      // console.log('!! filterData ::', filterData)
      const params:InterfaceFilterNewsData = { ...filterData }
      if (sortDirection.value === true) {
        params.sort = 'id'
      } else if (sortDirection.value === false) {
        params.sort = '-id'
      }
      // Convert status ALL to empty value.
      params['filter[status]'] === 'all'
        ? delete params['filter[status]']
        : true
      // console.log('!! params ::', params)
      clearTheNewsList()
      // Loading bar.
      newsLoading.value = true
      ApiService.query('/admin/news', { params })
        .then(response => {
          // console.log('response.data :: requestNews 1 ::', response.data)
          // Set the pagination parameters.
          setCurrentPage(response.data.page)
          newsPaginationPagesNumber.value = response.data.pages
          newsPaginationItemsNumber.value = response.data.total
          // Set items of news item.
          requestedNewsList.value = convertDataForEdit(response.data.items)
          // console.log('response.data :: requestNews 2 ::', response.data)
          newsLoading.value = false
        })
        .catch(() => {
          ElMessage.warning('Что-то не так с запросом новостей')
          newsLoading.value = false
        })
    }

    /**
     * Emitted action from the filter search button.
     * @param {Boolean} Whether to refresh pagination or no.
     */
    const filterButtonClick = pageClear => {
      if (pageClear) {
        filterData.page = 1
      }
      syncParamsToUrl(convertFilterToParams(filterData))
      requestNews()
    }

    /**
     * Detect at least the 1 checked entry.
     */
    const getCheckedEntriesIds = computed(() => {
      const idsArray = []
      requestedNewsList.value.forEach(item => {
        if (item.id && item.checked === true) {
          idsArray.push(item.id)
        }
      })
      return idsArray
    })

    /**
     * Sorting by ID.
     * After changing the direction making request for news.
     */
    const sortNews = () => {
      sortDirection.value = !sortDirection.value
      requestNews()
    }

    /**
     * Mounted hook.
     */
    onMounted(() => {
      requestReasons()
    })

    return {
      sortDirection,
      sortNews,
      getCheckedEntriesIds,
      rowClasses,
      calcWidth,
      generateNewsTitle,
      // Pagination
      setCurrentPage,
      // News
      requestNews,
      clearTheNewsList,
      convertDataForEdit,
      // Filter options
      filterData,
      // Reasons
      reasons,
      requestReasons,
      // Others
      filterButtonClick,
      checkedModel,
      tableHasContent,
      requestedNewsList,
      newsLoading,
      newsPaginationPagesNumber,
      newsPaginationItemsNumber,
      someCheckboxesAreChecked,
    }
  },
  computed: {
    /**
     * Detect reasons of checked entry.
     */
    getReasonsOfCheckedEntries() {
      const entriesObject = {}
      this.requestedNewsList.forEach(item => {
        if (item.id && item.checked === true && item.reasons.length) {
          entriesObject[item.id] = item.reasons.map(item => item.value.id)
        }
      })
      return entriesObject
    },
    /**
     * Finding both: checked items and unchecked reasons for an entry.
     * If at least one of checked items has no reason,
     * then we shouldn't allow the Bulk Declining
     * and should return FALSE
     * Should return TRUE only if all the entries has the selected reason.
     * 'Value 1' - if the item are checked, and the reason are checked.
     * 'Value 2' - if the item are checked, but without the reason.
     * 'Value 3' - if the item are NOT checked, but the reason are checked.
     * We should return FALSE if any of items matched the 'Value 2'.
     * @returns {boolean}.
     */
    detectUncheckedReasonsOnCheckedEntries() {
      const entries = this.requestedNewsList.map(item => {
        if (item.checked && item.reasons.length) {
          return 1
        } else if (item.checked && !item.reasons.length) {
          return 2
        } else if (!item.checked && item.reasons.length) {
          return 3
        }
      })
      const result = entries.some(item => item === 2)
      return result
    },
    /**
     * Title for the decline button.
     * @returns {String}.
     */
    declineButtonTitle() {
      let title = ''
      if (
        !this.someCheckboxesAreChecked &&
        !this.detectUncheckedReasonsOnCheckedEntries
      ) {
        title = 'Выберите хотя бы один элемент из списка'
      } else if (
        this.someCheckboxesAreChecked &&
        this.detectUncheckedReasonsOnCheckedEntries
      ) {
        title = 'Выберите причину отклонения для выбранных элементов'
      } else {
        title = 'Отклонить'
      }
      return title
    },
    /**
     * Detect the DISABLE status of the Bulk Decline button.
     * We should show the Bulk Decline button if:
     * 1. At least 1 entry are checked;
     * 2. Detect entries without the reason (have to be sure that every checked entry has a reason)
     * @returns {Boolean}.
     */
    bulkDeclineStatus() {
      return !this.someCheckboxesAreChecked
        || this.detectUncheckedReasonsOnCheckedEntries
    },
  },
  methods: {
    /**
     * Automatic checkbox selection when selecting the reason checkbox.
     * @param {Number} The news ID
     */
    checkItemOnReasonSelect(index) {
      const clickedNews = this.requestedNewsList[index]
      if (clickedNews.reasons.length) {
        clickedNews.checked = true
      } else {
        clickedNews.checked = false
      }
    },
    switchAllCheckboxes() {
      if (this.checkedModel) {
        this.requestedNewsList.forEach(item => {
          item.checked = false
        })
      } else {
        this.requestedNewsList.forEach(item => {
          item.checked = true
        })
      }
    },
    arrayConvertation(array) {
      const sources = []
      array.forEach(item => {
        sources.push(item.id)
      })
      return sources
    },
    entryPreparation(entry) {
      const entryCopy = { ...entry }
      const sources = this.arrayConvertation(entryCopy.sources)
      const adRubrics = this.arrayConvertation(entryCopy.ad_rubrics)
      entryCopy.sources = sources
      entryCopy['ad_rubrics'] = adRubrics
      entryCopy['user_id'] = 1
      return entryCopy
    },
    requestToAllowEntry(item) {
      return ApiService.post(`/admin/news/${item.id}/approve`, { id: item.id })
        .then(response => {
          return response.data.result
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: 'Что-то не так с одобрением новости',
          })
        })
    },
    allowEntry(item) {
      const entry = this.entryPreparation(item)
      this.$confirm(
        `Вы действительно хотите одобрить запись<br><strong>${entry.title}</strong>?`,
        'Внимание',
        {
          type: 'success',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Одобрить',
          dangerouslyUseHTMLString: true,
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              const resultOfAllowEntry = await this.requestToAllowEntry(entry)
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              if (resultOfAllowEntry) {
                this.requestNews()
                instance.confirmButtonLoading = false
                done()
              }
            } else {
              done()
            }
          },
        }
      )
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Одобрение подтверждено',
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: 'Одобрение отклонено',
          })
        })
    },
    requestToDeleteEntry(id) {
      const params = {
        id: id,
      }
      return ApiService.post(`/admin/news/${id}/destroy`, { params })
        .then(response => {
          return response.data.result
        })
        .catch(error => {
          this.$message({
            type: 'warning',
            message: 'Что-то не так с удалением новости',
          })
          return error
        })
    },
    deleteEntry(item) {
      this.$confirm(
        `Вы действительно хотите удалить запись<br><strong>${item.title}</strong>?`,
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Удалить',
          dangerouslyUseHTMLString: true,
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              const response = await this.requestToDeleteEntry(item.id)
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              if (response) {
                instance.confirmButtonLoading = false
                this.requestNews()
                done()
              }
            } else {
              done()
            }
          },
        }
      )
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Удаление подтверждено',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          })
        })
    },
    requestToAllowBulkEntries(ids) {
      const params = {
        ids: ids,
      }
      return ApiService.post('/admin/news/bulk-approve', params)
        .then(response => {
          return response.data.result
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: 'Что-то не так с одобрением новости',
          })
        })
    },
    allowBulkEntries() {
      this.$confirm(
        'Вы действительно хотите одобрить выбранные записи?',
        'Внимание',
        {
          type: 'success',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Одобрить',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              const result = await this.requestToAllowBulkEntries(
                this.getCheckedEntriesIds
              )
              if (result) {
                instance.confirmButtonLoading = false
                this.requestNews()
                done()
              }
              done()
            } else {
              done()
            }
          },
        }
      )
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Одобрение подтверждено',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Одобрение отменено',
          })
        })
    },
    requestToRejectBulkEntries(reasonsObject) {
      const params = {
        reasons: reasonsObject,
      }
      return ApiService.post('/admin/news/bulk-reject', params)
        .then(response => {
          return response.data.result
        })
        .catch(() => {
          return false
        })
    },
    rejectBulkEntries() {
      this.$confirm(
        'Вы действительно хотите отклонить выбранные записи?',
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Отклонить',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              const result = await this.requestToRejectBulkEntries(
                this.getReasonsOfCheckedEntries
              )
              if (result) {
                instance.confirmButtonLoading = false
                this.requestNews()
                this.$message({
                  type: 'success',
                  message: 'Новость отклонена',
                })
                done()
              } else {
                this.$message({
                  type: 'warning',
                  message: 'Что-то не так с отклонением новостей',
                })
                done()
              }
            } else {
              done()
            }
          },
        }
      )
    },
    requestToDeleteBulkEntries(ids) {
      const params = {
        ids: ids,
      }
      return ApiService.post('/admin/news/bulk-destroy', params)
        .then(response => {
          return response.data.result
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: 'Что-то не так с массовым удалением новостей',
          })
        })
    },
    deleteBulkEntries() {
      this.$confirm(
        'Вы действительно хотите удалить выбранные записи?',
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Удалить',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              const result = this.requestToDeleteBulkEntries(
                this.getCheckedEntriesIds
              )
              if (result) {
                instance.confirmButtonLoading = false
                this.requestNews()
                done()
              }
              done()
            } else {
              done()
            }
          },
        }
      )
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Удаление подтверждено',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          })
        })
    },
  },
})
