
import {
  ref,
  inject,
  computed,
  onMounted,
  defineComponent,
} from 'vue'
import store from '@/store/index'
import { Actions, Mutations } from '@/store/enums/store.enums'
import BasePre from '@/core/components/base/BasePre.vue'
import BaseTags from '@/core/components/base/BaseTags.vue'
import InterfaceFilterNewsData from '@/assets/ts/_utils/models/FilterNewsData'

export default defineComponent({
  name: 'news-filter',
  props: {
    pagesTotal: {
      default: 0,
      type: Number,
      required: true,
    },
    itemsTotal: {
      default: 0,
      type: Number,
      required: true,
    },
  },
  components: {
    BasePre,
    BaseTags,
  },
  setup(props, context) {

    const filterRubricsArray = ref([])
    const filterCategoriesArray = ref([])
    const subFilterIsOpened = ref(true)

    const filterData = inject('filterData') as InterfaceFilterNewsData

    /**
     * Toggle the subfilter on icon click.
     */
    const subFilterToggle = () => {
      subFilterIsOpened.value = !subFilterIsOpened.value
    }

    /**
     * Call the action for the categories request.
     */
    const filterCategoriesRequest = async () => {
      filterCategoriesArray.value = await store.dispatch(
        Actions.GET_CATEGORIES_LIST
      )
    }

    /**
     * Call the action for the rubrics request.
     */
    const rubricsRequest = async () => {
      // Get the list of rubrics.
      return filterRubricsArray.value = await store.dispatch(
        Actions.GET_RUBRICS_LIST,
        {
          limit: 10,
        }
      )
    }

    /**
     * Update the state of the list of rubrics.
     * @param {Array} Array of rubrics.
     */
    const updateStateRubricsList = async data => {
      store.commit(Mutations.SET_RUBRICS_LIST_MUTATION, data)
    }

    /**
     * If we got the flat list,
     * then we need to convert it to the tree.
     * @param {Array} Array of rubrics.
     */
    const convertRubricsListToTree = async data => {
      await store.dispatch(Actions.SET_RUBRICS_TREE, data)
    }

    /**
     * Call the getter for rubrics convertation.
     */
    const rubricsTree = computed(() => {
      return store.getters.getRubricsTree
    })

    /**
     * Actions on search button click/tap/enter keyup.
     */
    const filterButtonClick = () => {
      context.emit('filterButtonClick', true)
    }

    /**
     * Detect whether the table has a content.
     * Needed for the table loader.
     * @returns {boolean}.
     */
    const showPagination = computed(() => {
      return props.pagesTotal > 1 ? true : false
    })

    const paginationClick = val => {
      filterData.page = val
      context.emit('filterButtonClick')
    }

    /**
     * Parses the window URL for params to apply them to the filter.
     */
    const parseParamsFromUrl = () => {
      const searchString = window.location.search.replace('?', '')
      const urlSearchParams = new URLSearchParams(searchString)
      return Array.from(urlSearchParams);
    }

    /**
     * Set the default value for the FilterData
     * when there is no params in the URL.
     * Uses only in the page load stage.
     */
    const setDefaultFilterData = () => {
      filterData.page = 1
      filterData.limit = 12
      filterData['filter[status]'] = 'not_verified'
    }

    /**
     * Converts array of params to filterData object.
     * @param {Array} Array of params.
     */
    const convertParamsFromArrayToObject = array => {
      array.forEach(([key, value]) => {
        if (key === 'filter[category]' || key === 'filter[ad_rubric_id]') {
          const newArray: number[] = value.split(',').map(Number)
          filterData[key] = newArray
        } else if (key === 'filter[user_id]' || key === 'filter[id]') {
          const newArray: string[] = value.split(',')
          filterData[key] = newArray
        } else {
          filterData[key] = value
        }
      })
    }

    /**
     * Mounted hook.
     */
    onMounted(async () => {
      // Get the list of news categories.
      await filterCategoriesRequest()
      // Get the list of news rubrics.
      if (!store.state.RubricsModule.rubricsTree.length) {
        const rubrucsList = await rubricsRequest()
        if (rubrucsList.length) {
          await updateStateRubricsList(rubrucsList)
          await convertRubricsListToTree(rubrucsList)
        }
      }
      // Parse the URL and convert it to an array.
      const parsedArray = parseParamsFromUrl()
      if (parsedArray.length) {
        // Fill in the FilterData object
        convertParamsFromArrayToObject(parsedArray)
      } else {
        setDefaultFilterData()
      }
      // Get params on the page reloading.
      context.emit('filterButtonClick')
    })

    return {
      // Pagination
      showPagination,
      paginationClick,
      // Filter
      filterData,
      rubricsTree,
      filterButtonClick,
      subFilterToggle,
      subFilterIsOpened,
      parseParamsFromUrl,
      filterCategoriesArray,
    }
  },
})
