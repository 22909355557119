import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b28fc858"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "base-pre"
}
const _hoisted_2 = { class: "h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.hide)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("pre", null, "      " + _toDisplayString(_ctx.data) + "\n    ", 1)
      ]))
    : _createCommentVNode("", true)
}